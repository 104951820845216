<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <Head>
      <Title>
        {{
          pageSeoData.title ||
            '«ІБІС» - полювання та риболовля - збройовий магазин в Україні'
        }}
      </Title>
      <Link id="canonical-url" rel="canonical" :href="canonical" />
      <Meta
        id="og-title"
        property="og:title"
        :content="
          pageSeoData.title ||
            '«ІБІС» - полювання та риболовля - збройовий магазин в Україні'
        "
      />
      <Meta
        id="description-meta"
        name="description"
        :content="
          pageSeoData.description ||
            'Магазин «ІБІС» Україна - в наших магазинах представлено більше 80 000 найменувань товарів для полювання, риболовлі, туризму та активного відпочинку від 200 світових брендів.'
        "
      />
      <Meta
        id="og-description"
        property="og:description"
        :content="
          pageSeoData.description ||
            'Магазин «ІБІС» Україна - в наших магазинах представлено більше 80 000 найменувань товарів для полювання, риболовлі, туризму та активного відпочинку від 200 світових брендів.'
        "
      />
      <Meta id="robots-meta" name="robots" :content="pageSeoData?.robots" />
      <Meta
        id="og-image"
        property="og:image"
        content="https://ibis.net.ua/static/image_cache/f/c/968x504_f_ibis_blogo.jpg"
      />
    </Head>
    <Body>
      <div class="contents">
        <div class="bg-white isolate z-[200]">
          <!-- <TheBanner /> -->
          <div class="border-b border-gray block">
            <div class="container h-[51px] lg:h-[69px] flex items-center">
              <div
                class="flex flex-1 h-16 items-center justify-between gap-4 relative"
              >
                <NuxtLink :to="localePath('/')">
                  <IconsLogo class="h-6 lg:h-[37px]" />
                </NuxtLink>
                <AppSiteToggle class="mx-auto lg:ml-0" />
                <AppHeaderContactsMenu />
              </div>
            </div>
          </div>
        </div>
        <div id="mainContentWrapper" class="flex flex-col flex-1">
          <main class="flex-1 bg-gray">
            <slot></slot>
          </main>

          <footer>
            <AppFooterBottomBanner />
          </footer>
        </div>

        <client-only>
          <AppDialogsCallBack v-model="dialog" />
          <AppDialogsAuth />
          <LazyAppCart />
        </client-only>
      </div>
    </Body>
  </Html>
</template>

<script setup>
import trimEnd from 'lodash/trimEnd'
import { useSettingsStore } from '~/stores/settings'
import { useSeoStore } from '~/stores/seo'

const storeType = useStoreType()

const head = useLocaleHead({
  addDirAttribute: false,
  identifierAttribute: 'id',
  addSeoAttributes: true
})

useTrackers()

const host = computed(() => {
  return storeType.isHunting ? storeType.huntingDomain : storeType.fishingDomain
})

const lang = computed(() => useNuxtApp()?.$i18n?.locale?.value || 'uk')

const pageSeoData = computed(() => useSeoStore().seoData || {})

const canonical = computed(() => {
  let link = host.value
  if (pageSeoData.value?.canonical) {
    if (lang.value === 'ru') {
      link = `${host.value}/ru/${pageSeoData.value?.canonical}`
    } else {
      link = `${host.value}/${pageSeoData.value?.canonical}`
    }
  }
  return `${trimEnd(link, '/')}/`
})

onMounted(() => {
  useSeoStore().setSeoData({})
})

// useAsyncData('user-profile', () => useProfile())
const dialog = ref(null)
provide('callBackDialog', dialog)

useAsyncData('settings-data', () => useSettingsStore().fetchSettings())

watch(
  () => useRoute().path,
  () => {
    process.client &&
      eS('sendEvent', 'PageView', {
        PageView: {}
      })
  }
)

try {
  useSchemaOrg([
    {
      '@type': 'Organization',
      name: '«Ібіс» - зброя та полювання',
      logo: new URL(
        '/assets/images/ibis_blogo.jpg',
        useRuntimeConfig().public.originDomain
      )?.href,
      image: new URL(
        '/assets/images/ibis_blogo.jpg',
        useRuntimeConfig().public.originDomain
      )?.href,
      url: useRuntimeConfig().public.originDomain,
      // email: ['webstore@sporting.com.ua', 'fishing.ibis@gmail.com'],
      // telephone: '0 800 600 002',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '0 800 600 002'
        },
        {
          '@type': 'ContactPoint',
          email: 'webstore@sporting.com.ua'
        },
        {
          '@type': 'ContactPoint',
          email: 'fishing.ibis@gmail.com'
        }
      ]
    },
    {
      '@type': 'WebSite',
      name: '«Ібіс» - зброя та полювання',
      url: useRuntimeConfig().public.originDomain,
      potentialAction: {
        '@type': 'SearchAction',
        target: new URL(
          '/search/?searchstring={search_term_string}',
          useRuntimeConfig().public.originDomain
        )?.href,
        'query-input': 'required name=search_term_string'
      }
    }
  ])
} catch (e) {
  console.log('useSchemaOrg error')
}
</script>
